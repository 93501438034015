<template>
  <!-- 员工照片采集页 -->
  <div class="faceCollection">
    <van-nav-bar :title="$t('faceCollection.FcText1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft">
      <template #right>
        <van-tag v-if="otherUserName"
                 closeable
                 size="medium"
                 type="success"
                 @close="closeOtherEmp">
          {{ otherUserName }}
        </van-tag>
        <van-icon v-if="can_query_branch_record"
                  name="friends-o"
                  size="18"
                  color="#ffffff"
                  style="margin-right: 6px"
                  @click="checkOtherEmp" />
      </template>
    </van-nav-bar>
    <div class="overflow_div">
      <div class="face_box">
        <div class="btn_box">
          <van-button v-if="use_emppic"
                      plain
                      size="small"
                      :class="activeImg==3?'activebtn':''"
                      type="info"
                      @click="activeImg=3">{{$t('faceCollection.FcText8')}}</van-button>
          <van-button v-if="use_cappic1"
                      plain
                      size="small"
                      :class="activeImg==1?'activebtn':''"
                      type="info"
                      @click="activeImg=1">{{$t('faceCollection.FcText5')}}</van-button>
          <van-button v-if="use_cappic2"
                      plain
                      size="small"
                      :class="activeImg==2?'activebtn':''"
                      type="info"
                      @click="activeImg=2">{{$t('faceCollection.FcText6')}}</van-button>
        </div>
        <div class="images_box">
          <template v-if="use_cappic1 && activeImg==1">
            <div v-if="loading1"
                 class="loading">
              <van-loading type="spinner"
                           color="#1989fa" />
            </div>
            <van-image v-else
                       fit="contain"
                       :src="pic1" />
          </template>
          <template v-if="use_cappic2 && activeImg==2">
            <div v-if="loading2"
                 class="loading">
              <van-loading type="spinner"
                           color="#1989fa" />
            </div>
            <van-image v-else
                       fit="contain"
                       :src="pic2" />
          </template>
          <template v-if="use_emppic && activeImg==3">
            <div v-if="loading3"
                 class="loading">
              <van-loading type="spinner"
                           color="#1989fa" />
            </div>
            <van-image v-else
                       fit="contain"
                       :src="pic3" />
          </template>
        </div>
        <div class="upload_box"
             v-if="$t('faceCollection.FcText5')|| $t('faceCollection.FcText6') || $t('faceCollection.FcText8')">
          <van-uploader v-if="use_cappic1 && $t('faceCollection.FcText5') && activeImg==1"
                        accept="image/*"
                        capture="camera"
                        :max-count="1"
                        :after-read="afterRead1">
            <van-button size="small"
                        :icon="require('@assets/img/face_pic.png')"
                        type="info">{{$t('faceCollection.FcText5')}}</van-button>
          </van-uploader>
          <van-uploader v-if="use_cappic2 && $t('faceCollection.FcText6') && activeImg==2"
                        accept="image/*"
                        :max-count="1"
                        :after-read="afterRead2"
                        capture="camera">
            <van-button size="small"
                        :icon="require('@assets/img/face_pic.png')"
                        type="info">{{$t('faceCollection.FcText6')}}</van-button>
          </van-uploader>
          <van-uploader v-if="use_emppic && $t('faceCollection.FcText8') && activeImg==3"
                        accept="image/*"
                        :max-count="1"
                        :after-read="afterRead3"
                        capture="camera">
            <van-button size="small"
                        :icon="require('@assets/img/face_pic.png')"
                        type="info">{{$t('faceCollection.FcText8')}}</van-button>
          </van-uploader>
        </div>
        <div class="user_box">
          <div class="user1">
            <span>{{$t('faceCollection.FcText2')}}</span>
            <span>{{ userData.empcode }}</span>
          </div>
          <div class="user2">
            <span>{{$t('faceCollection.FcText3')}}</span>
            <span>{{ userData.empname }}</span>
          </div>
          <div class="user3">
            <span>{{$t('faceCollection.FcText4')}}</span>
            <span>{{ userData.deptname }}</span>
          </div>
        </div>
      </div>
      <div class="description">
        <div class="title">拍摄须知</div>
        <div class="pic_box">
          <div class="pic_item">
            <van-image fit="contain"
                       :src="require('@assets/img/face_gather1.png')" />
            <div class="describe">
              <van-icon name="success"
                        color="#00AE66" />
              <span>白底照片</span>
            </div>
          </div>
          <div class="pic_item">
            <van-image fit="contain"
                       :src="require('@assets/img/face_gather2.png')" />
            <div class="describe">
              <van-icon name="cross"
                        color="#E50530" />
              <span>遮挡面部</span>
            </div>
          </div>
          <div class="pic_item">
            <van-image fit="contain"
                       :src="require('@assets/img/face_gather3.png')" />
            <div class="describe">
              <van-icon name="cross"
                        color="#E50530" />
              <span>光线不全</span>
            </div>
          </div>
          <div class="pic_item">
            <van-image fit="contain"
                       :src="require('@assets/img/face_gather4.png')" />
            <div class="describe">
              <van-icon name="cross"
                        color="#E50530" />
              <span>过度美颜</span>
            </div>
          </div>
        </div>
        <div class="desc">1、人脸正面免冠照，露出眉毛和眼睛； </div>
        <div class="desc">2、照片白底、无逆光、无ps、无过度美颜处理；</div>
        <div class="desc">3、必须是jpg 格式，文件大小小于 200KB；</div>
        <div class="desc">4、图片背景尽量干净，不要出现接近肤色元素，导致人脸识别失败；</div>
        <div class="desc">5、人脸要足够大，以免人眼间距小无法识别，两眼瞳距不低于 60 像素点；</div>
        <div class="img_box">
          <div class="oneimg">
            <van-image fit="contain"
                       :src="require('@assets/img/face_gather5.png')" />
          </div>
          <div class="oneimg">
            <van-image fit="contain"
                       :src="require('@assets/img/face_gather6.png')" />
          </div>
          <div class="oneimg">
            <van-image fit="contain"
                       :src="require('@assets/img/face_gather7.png')" />
          </div>

        </div>
      </div>
    </div>

    <!-- 选择人员树弹窗 -->
    <PopTree ref="popTreeNode"
             :whichTree="'employee'"
             module="201"
             :multiseltree="false"
             @treeSelectValEvent="treeSelectValEvent"></PopTree>
  </div>
</template>

<script>
import { Toast, Dialog, Notify } from 'vant'
// import Compressor from 'compressorjs'
import * as imageConversion from 'image-conversion'
import { getEmpInfoByEmpCode, upLoadFile, getCapPicPath, getEmpPicPath, getHrQueryPageInfo } from '@api/wx.js'
import { getDownLoadFileName } from '@api/wxzp.js'
import { checkUserLogin } from '@api/login.js'
import { getOpenId } from '@/utils/auth'
import PopTree from '@components/PopTree/index.vue'
export default {
  components: {
    PopTree
  },
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      empid: '',
      empcode: '',
      pic1: require('@assets/img/login.png'),
      pic2: require('@assets/img/login.png'),
      pic3: require('@assets/img/login.png'),
      userData: {},
      use_cappic1: 0,
      use_cappic2: 0,
      loading1: false,
      loading2: false,
      loading3: false,
      CapPicpath: 'CapPic',
      activeImg: 1,
      use_emppic: window.use_emppic || 0,
      empPicPath: '',
      otherUserName: '',
      can_query_branch_record: false
    }
  },
  created () {
    this.use_cappic1 = window.use_cappic1 || 0
    this.use_cappic2 = window.use_cappic2 || 0
    this.use_emppic = window.use_emppic || 0
    this.activeImg = this.use_cappic1 ? 1 : this.use_cappic2 ? 2 : this.use_emppic ? 3 : 1

    this.empid = this.userInfo.empid
    this.empcode = this.userInfo.empcode
    this._getCapPicPath()
    this._getEmpPicPath()
    // 触发一次登陆，保证单点登陆也有openid
    this.checkUserLogin()
    this.getHrQueryPageInfoData()
  },
  methods: {
    // 获取查询页面相关配置信息
    getHrQueryPageInfoData () {
      getHrQueryPageInfo({
        moduleno: 201,
        empcode: this.userInfo.empcode,
        langid: localStorage.getItem('langid')
      }).then(res => {
        let pageinfo = res.pageinfo[0]
        this.can_query_branch_record = pageinfo.can_query_branch_record
      })
    },
    // 杀人
    closeOtherEmp () {
      this.otherUserName = ''
      this.empid = this.userInfo.empid
      this.empcode = this.userInfo.empcode
      this._getCapPicPath()
      this._getEmpPicPath()
      // 触发一次登陆，保证单点登陆也有openid
      this.checkUserLogin()
    },
    // 选择人员弹窗回调
    treeSelectValEvent (data) {
      console.log(data, 'data');
      this.otherUserName = data.empname
      this.empid = data.empid
      this.empcode = data.empcode
      this._getCapPicPath()
      this._getEmpPicPath()
      // 触发一次登陆，保证单点登陆也有openid
      this.checkUserLogin()
    },
    // 选人
    checkOtherEmp () {
      this.$refs.popTreeNode.module = '201'
      this.$refs.popTreeNode.treename = 'emptree'
      this.$refs.popTreeNode.fieldname = ''
      this.$refs.popTreeNode.editstate = 2
      this.$refs.popTreeNode.showEmpTreePop('员工')
    },
    checkUserLogin () {
      let hasOpenId = getOpenId()
      if (hasOpenId === '0') hasOpenId = ''
      checkUserLogin({
        username: this.userInfo.username,
        pwd: localStorage.getItem('v8_login_pwd'),
        langid: localStorage.getItem('langid') || 0,
        openid: hasOpenId || ''
      }).then(res => {
        if (res.iserror != '0')
        {
          Notify({
            type: 'danger',
            message: res.errormsg
          })
        }
      })
    },
    _getEmpPicPath () {
      getEmpPicPath().then(res => {
        this.empPicPath = res
        this.getZhengJianPic()
      })
    },
    _getCapPicPath () {
      getCapPicPath().then(res => {
        this.CapPicpath = res
      }).finally(() => {
        // 获取用户信息
        this.getEmpInfoByEmpCodeData()
        this.getDownLoadFileNameData(5)
        this.getDownLoadFileNameData(6)
      })
    },
    onClickLeft () {
      // this.$router.back()
      if (this.$route.query.from == 'home')
      {
        this.$router.push('/home')
      } else
      {
        this.$router.push('/personCen')
      }
    },
    // 获取证件照
    getZhengJianPic () {
      getDownLoadFileName({
        dflag: 4,
        moduleno: 201,
        autoid: this.empid,
        filename: 'emp_' + this.empid,
        fileext: '.jpg',
        downloadpath: this.empPicPath
      }).then(res => {
        this.pic3 = res + '?v=' + Date.parse(new Date()) + 120
      })
    },
    // 获取自拍照1和自拍照2的地址
    getDownLoadFileNameData (dflag) {
      getDownLoadFileName({
        dflag: dflag,
        moduleno: 201,
        autoid: this.empid,
        filename: 'CapPic',
        fileext: '.jpg',
        downloadpath: this.CapPicpath
      }).then(res => {
        if (dflag == 5)
        {
          this.pic1 = res + '?v=' + Date.parse(new Date())
        } else if (dflag == 6)
        {
          this.pic2 = res + '?v=' + Date.parse(new Date()) + 60
        }
      })
    },
    // 获取用户信息
    getEmpInfoByEmpCodeData () {
      getEmpInfoByEmpCode({
        empcode: this.empcode
      }).then(res => {
        this.userData = res.data[0]
      })
    },
    // 拍照1上传
    async afterRead1 (file) {
      this.loading1 = true
      const base64s = file.content
      let realFile = file.file
      let f = name || realFile.name
      const ext = f.substring(f.lastIndexOf('.') + 1)
      if ('png|jpg|jpeg|emp'.includes(ext))
      {
        // 如果是图片，则先压缩，防止提交失败
        realFile = await this.compressorImage(realFile)
      }
      let newFile = null
      newFile = await this.reFileName('image/jpg', realFile, 'CapPic.jpg')
      let params = {
        file: newFile,
        upflag: 5,
        moduleno: 201,
        autoid: this.empid,
        uploadpath: this.CapPicpath,
        username: this.userInfo.username,
        packagetype: ''
      }
      upLoadFile(params).then(res => {
        this.loading1 = false
        if (res == 'OK')
        {
          // this.pic1 = base64s
          this.getDownLoadFileNameData(5)
          Toast.success(this.$t('faceCollection.FcText7'))
        } else
        {
          Dialog.alert({
            message: res
          }).then(() => {
            // on close
          })
        }
      })
    },
    // 拍照2上传
    async afterRead2 (file) {
      this.loading2 = true
      const base64s = file.content
      let realFile = file.file
      let f = name || realFile.name
      const ext = f.substring(f.lastIndexOf('.') + 1)
      if ('png|jpg|jpeg|emp'.includes(ext))
      {
        // 如果是图片，则先压缩，防止提交失败
        realFile = await this.compressorImage(realFile)
      }
      let newFile = null
      newFile = await this.reFileName('image/jpg', realFile, 'CapPic.jpg')
      let params = {
        file: newFile,
        upflag: 6,
        moduleno: 201,
        autoid: this.empid,
        uploadpath: this.CapPicpath,
        username: this.userInfo.username,
        packagetype: ''
      }
      upLoadFile(params).then(res => {
        this.loading2 = false
        if (res == 'OK')
        {
          // this.pic2 = base64s 
          this.getDownLoadFileNameData(6)
          Toast.success(this.$t('faceCollection.FcText7'))
        } else
        {
          Dialog.alert({
            message: res
          }).then(() => {
            // on close
          })
        }
      })
    },
    // 证件照上传
    async afterRead3 (file) {
      this.loading3 = true
      const base64s = file.content
      let realFile = file.file
      let f = name || realFile.name
      const ext = f.substring(f.lastIndexOf('.') + 1)
      if ('png|jpg|jpeg|emp'.includes(ext))
      {
        // 如果是图片，则先压缩，防止提交失败
        realFile = await this.compressorImage(realFile)
      }
      let newFile = null
      newFile = await this.reFileName('image/jpg', realFile, 'emp_' + this.empid + '.jpg')
      let params = {
        file: newFile,
        upflag: 4,
        moduleno: 201,
        autoid: this.empid,
        uploadpath: this.empPicPath,
        username: this.userInfo.username,
        packagetype: ''
      }
      upLoadFile(params).then(res => {
        this.loading3 = false
        if (res == 'OK')
        {
          // this.pic3 = base64s
          Toast.success(this.$t('faceCollection.FcText7'))
          this.getZhengJianPic()
        } else
        {
          Dialog.alert({
            message: res
          }).then(() => {
            // on close
          })
        }
      })
    },
    // 压缩图片
    compressorImage (file) {
      return new Promise((resolve, reject) => {
        imageConversion.compressAccurately(file, 500).then(res => {
          res = new File([res], file.name, { type: res.type, lastModified: Date.now() })
          resolve(res)
        })
      })
    },
    // 重命名file
    async reFileName (type, file, name) {
      const tempFile = file
      const size = tempFile.size
      let uploadFile = new File([tempFile], name, { type, size })
      return uploadFile
      //调用文件上传接口上传文件
    }
  }
}
</script>

<style scoped lang="less">
.faceCollection {
  height: 100vh;
  overflow: hidden;
  // background: #eceaea;
  .van-hairline--bottom::after {
    border-bottom-width: 0;
  }
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-tag {
        margin-right: 24px;
        font-size: 24px;
        .van-icon {
          color: #ffffff;
        }
      }
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 100px);
    .face_box {
      margin: 20px;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(82, 155, 178, 0.16);
      border-radius: 16px;
      .btn_box {
        display: flex;
        justify-content: space-evenly;
        .van-button {
          border-radius: 8px;
          padding: 0 30px;
        }
        .activebtn {
          background: #bfdcfb;
        }
      }
      .images_box {
        margin-top: 60px;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-around;
        .loading {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40%;
        }
        .van-image {
          // width: 40%;
          width: 400px;
          height: 400px;
          /deep/ img {
            box-shadow: 0 0 60px rgba(43, 141, 240, 0.3);
            border-radius: 50%;
          }
        }
      }
      .upload_box {
        display: flex;
        justify-content: space-around;
        .van-uploader {
          width: 40%;
          /deep/.van-uploader__wrapper {
            .van-uploader__input-wrapper {
              width: 100%;
              .van-button {
                width: 100%;
              }
            }
          }
        }
      }
      .user_box {
        padding-top: 40px;
        padding-left: 20px;
        font-size: 28px;
        color: #666666;
        .user1,
        .user2 {
          margin-bottom: 10px;
        }
        .user3 {
          width: 50%;
        }
      }
    }

    .description {
      margin: 20px;
      padding: 20px;
      background: #ffffff;
      box-shadow: 0px 3px 6px 1px rgba(82, 155, 178, 0.16);
      border-radius: 16px;
      .title {
        font-size: 32px;
        margin-bottom: 20px;
      }
      .pic_box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        .pic_item {
          width: 24%;
          text-align: center;
          .describe {
            display: flex;
            align-items: center;
            justify-content: center;
            .van-icon {
              font-size: 24px;
            }
            font-size: 24px;
            color: #999999;
          }
        }
      }
      .desc {
        padding-left: 20px;
        margin-bottom: 10px;
        color: #999999;
        font-size: 24px;
      }
      .img_box {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .oneimg {
          width: 30%;
        }
      }
    }
  }
}
</style>
